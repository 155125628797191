
const navigation = () => {

	toggleIcon();
	navigationExpandIcons();
	navigationExpandText();
	submenuArrow();

}

const toggleIcon = () => {

	let toggle_icon = document.getElementsByClassName('toggle-navigation');

	if (!toggle_icon) return false;

	for (let i = 0; i < toggle_icon.length; i++) {
		toggle_icon[i].addEventListener('click', function (e) {
			if (document.body.classList.contains('reveal-navigation')) {
				document.body.classList.remove('reveal-navigation');
			} else {
				document.body.classList.add('reveal-navigation');
			}
			e.preventDefault();
		});
	}

	document.onkeydown = function (evt) {
		evt = evt || window.event;
		if (evt.keyCode == 27) {
			document.body.classList.remove('reveal-navigation');
			document.body.classList.remove('reveal-download-modal');
			document.body.classList.remove('reveal-newsletter-modal');
			document.body.classList.remove('reveal-contact-modal');
		}
	};

};

const navigationExpandText = () => {

	let links = document.querySelectorAll('#menu-header-primary > .menu-item-has-children > a');

	if (!links) return false;

	for (const link of links) {

		link.setAttribute('data-barba-prevent', 'true');

		link.addEventListener('click', function (e) {
			e.preventDefault();
			if (link.nextSibling.nextSibling.classList.contains('sub-menu') && link.nextSibling.nextSibling.classList.contains('expand')) {
				link.nextSibling.nextSibling.classList.remove('expand');
			} else if (link.nextSibling.nextSibling.classList.contains('sub-menu')) {
				link.nextSibling.nextSibling.classList.add('expand');
			}

			e.preventDefault();
			return false;

		});

	}
}

const navigationExpandIcons = () => {

	let items = document.getElementsByClassName('menu-item-has-children');

	if (!items) return false;

	for (const item of items) {

		const expand_icon = document.createElement("div");

		expand_icon.classList.add('navigation-expand-icon');

		item.appendChild(expand_icon);

		expand_icon.addEventListener('click', function (e) {
			if (expand_icon.previousSibling.previousSibling.classList.contains('expand')) {
				expand_icon.previousSibling.previousSibling.classList.remove('expand');
				expand_icon.style.transform = 'rotate(-90deg)';
			} else {
				expand_icon.previousSibling.previousSibling.classList.add('expand');
				expand_icon.style.transform = 'rotate(0)';
			}
		});

	}

}

const submenuArrow = () => {

	let arrows = document.querySelectorAll('[data-toggle-submenu-arrow]')

	if (!arrows.length) return
	// console.log('33')

	arrows.forEach(arrow => {
		arrow.addEventListener('click', () => {
			console.log('44')
			let li = arrow.parentElement.parentElement
			if (li.getAttribute('data-submenu-status') == 'closed') {
				li.setAttribute('data-submenu-status', 'open')
			} else {
				li.setAttribute('data-submenu-status', 'closed')
			}
		})
	})

}

export default navigation;