import Swiper, {Pagination, A11y} from 'swiper';
Swiper.use([Pagination, A11y]);

const swiperTestimonials = () => {

  const swiper = document.querySelectorAll('[data-testimonials-swiper]');

  if (!swiper) return false;

  var testimonial_swiper = new Swiper('[data-testimonials-swiper]', {
    slidesPerView: 'auto',
    centeredSlides: true,
    loop: true,
    grabCursor: true,
    pagination: {
      el: '[data-testimonials-swiper-pagination]',
      clickable: true,
    },
  });

}

export default swiperTestimonials;
