const calculator = () => {

  let query_range_inputs = document.querySelectorAll('[data-queries-range]'),
      query_number_inputs = document.querySelectorAll('[data-queries-number]')

  if (!query_range_inputs || !query_number_inputs) return false

  for (const query_range_input of query_range_inputs) {

    query_range_input.addEventListener('change', () => {
      calculateTotals()
    })

    query_range_input.addEventListener('input', (e) => {
      let calc_costs_saved = ( ( e.target.value * e.target.nextElementSibling.getAttribute('data-minutes') ) / 60 ) * e.target.nextElementSibling.getAttribute('data-multiplier')
      e.target.nextElementSibling.value = e.target.value
      e.target.nextElementSibling.nextElementSibling.value = Math.round(calc_costs_saved)
    })

  }

  for (const query_number_input of query_number_inputs) {

    query_number_input.addEventListener('input', (e) => {
      let calc_costs_saved = ( ( e.target.value * e.target.getAttribute('data-minutes') ) / 60 ) * e.target.getAttribute('data-multiplier')
      e.target.previousElementSibling.value = e.target.value
      e.target.nextElementSibling.value = Math.round(calc_costs_saved)
      calculateTotals()
    })
  }

}

const calculateTotals = () => {

  let calc_total_minutes_saved = 0,
      calc_total_hours_saved = 0,
      calc_total_costs_saved = 0

  let query_number_inputs = document.querySelectorAll('[data-queries-number]')

  for (const query_number_input of query_number_inputs) {
    if ( query_number_input.value >= 100 ) {
      calc_total_minutes_saved += query_number_input.value * query_number_input.getAttribute('data-minutes')
      calc_total_hours_saved += ( query_number_input.value * query_number_input.getAttribute('data-minutes') ) / 60,
      calc_total_costs_saved += ( ( query_number_input.value * query_number_input.getAttribute('data-minutes') ) / 60 ) * query_number_input.getAttribute('data-multiplier')
    }
  }

  let total_minutes_saved_field = document.querySelector('[data-total-minutes-saved]'),
      total_hours_saved_field = document.querySelector('[data-total-hours-saved]'),
      total_costs_saved_field = document.querySelector('[data-total-costs-saved]')

  total_minutes_saved_field.value = Math.round(calc_total_minutes_saved)
  total_hours_saved_field.value = Math.round(calc_total_hours_saved)
  total_costs_saved_field.value = 'lol'
console.log('why tho')
}

export default calculator