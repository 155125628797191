
const billingTabs = () => {

	let tabs = document.querySelectorAll('[data-pricing-tab]'),
		parent = document.querySelector('[data-pricing-current]')

	if (!tabs.length) return false

	for (const tab of tabs) {

		tab.addEventListener('click', function (e) {

			let value = tab.getAttribute('data-pricing-tab')
			parent.setAttribute('data-pricing-current', value)

			e.preventDefault()

		})
	}
}

export default billingTabs
