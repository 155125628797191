import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const defaultEnter = () => {

}

export const animateEnter = () => {

  let items = gsap.utils.toArray('[data-enter]');

  if (!items) return false;

  for (const item of items) {

    let set_delay = item.hasAttribute('data-delay') ? item.getAttribute('data-delay') : 0.1;

    if ( item.hasAttribute('data-root-trigger') ) {
      var set_trigger = document.getElementById('container');;
    } else if ( item.hasAttribute('data-parent-trigger') ) {
      var set_trigger = item.parentElement;
    } else {
      var set_trigger = item;
    }

    gsap.to(item, {
      y: 0,
      x: 0,
      opacity: 1,
      ease: 'power3.out',
      duration: 1,
      delay: set_delay,
      scrollTrigger: {
        trigger: set_trigger,
        start: 'top 80%',
        toggleActions: "play complete complete reverse",
        // markers: true,
      }
    });

  }
}

export default defaultEnter;
