import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export const slidesPin = () => {

    let parent = document.querySelector('[data-current-slide]')
    
    if (!parent || window.innerWidth < 782) return

    let slides = parent.querySelectorAll('[data-slide-pin]')

    slides.forEach((slide, i) => {
        gsap.to(slide, {
            scrollTrigger: {
                trigger: slide,
                start: 'center center+=55',
                end: 'center center+=55',
                endTrigger: slides[slides.length-1],
                pin: true,
                pinSpacing: false,
                anticipatePin: true,
                // markers: true,
                onEnter: e => {
                    parent.setAttribute('data-current-slide', i+1)
                },
                onLeaveBack: e => {
                    parent.setAttribute('data-current-slide', i)
                }
            }
        })
    })

}

export default slidesPin;
