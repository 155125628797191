const accordion = () => {

  let items = document.getElementsByClassName('accordion-item');

  if (!items) return false;

  for (const item of items) {

    item.addEventListener('click', function(e) {
      if ( this.classList.contains('open') ) {
        this.classList.remove('open');
      } else {
        this.classList.add('open');
      }
    });

  }

};

export default accordion;