import Swiper, {Pagination, EffectFade, Autoplay, A11y } from 'swiper';
Swiper.use([Pagination, EffectFade, Autoplay, A11y]);

const swiperTrustedBy = () => {

  const swiper = document.querySelectorAll('[data-trusted-by-swiper]');

  if (!swiper) return false;

  let trusted_by_swiper = new Swiper('[data-trusted-by-swiper]', {
    effect: "fade",
    speed: 1000,
    fadeEffect: { crossFade: true },
    loop: true,
    autoplay: {
      delay: 2000,
    },
  });

}

export default swiperTrustedBy;
