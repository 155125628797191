import gsap from 'gsap';
import barba from '@barba/core';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import { animateEnter } from './animateEnter';
import sellingPoints from './sellingPoints';
import homeBanner from './homeBanner';
import initProcessAutopilot from './processAutopilot';
import initProcessHubspot from './processHubspot';
import amenities from './amenities';
import accordion from './accordion';
import billingTabs from './billingTabs';
import pricingTabs from './pricingTabs';
import { conversationsHeader } from './conversationsHeader';
import swiperTestimonials from './swiperTestimonials';
import swiperTrustedBy from './swiperTrustedBy';
import swiperRelatedArticles from './swiperRelatedArticles';
import { openModals } from './modals';
import chatNowLink from './chatNowLink';
import calculator from './calculator';
import slidesPin from './slidesPin';

const initBarba = () => {

  barba.init({
    prevent: function (param) {
      return param.el.getAttribute('href').indexOf('wp-admin') !== -1 || param.el.getAttribute('href').indexOf('wp-login') !== -1 || param.el.getAttribute('href').indexOf('modal') !== -1;
    },
    transitions: [{
      name: 'default',
      once(data) {
        if (data.next.namespace == 'conversation') {
          document.body.classList.add('show-conversation-header');
        } else {
          document.body.classList.remove('show-conversation-header');
        }
        setTimeout(function () {
          document.body.classList.add('open-shutter');
        }, 100);
        setTimeout(function () {
          animateEnter();
          conversationsHeader();
          openModals();
        }, 600);
        setTimeout(function () {
          data.next.container.classList.add('ready');
        }, 1200);
        setTimeout(function () {
          document.body.classList.add('show-header');
        }, 1600);
      },
      leave(data) {
        let items = document.getElementsByClassName('menu-item');
        for (const item of items) {
          if (item.classList.contains('menu-item-has-children')) {
            item.classList.remove('menu-item-has-children');
            item.classList.add('menu-item-deactivated');
          }
        }
        document.body.classList.remove('reveal-navigation');
        let shutter = document.getElementById('transition-shutter');
        shutter.classList.add('activate');
        const done = this.async();
        setTimeout(() => { done() }, 800);
      },
      enter(data) {
        window.scrollTo(0, 0);
        if (data.next.namespace == 'conversation') {
          document.body.classList.add('show-conversation-header');
        } else {
          document.body.classList.remove('show-conversation-header');
        }
      },
      after(data) {
        if (data.next.namespace == 'homepage') {
          document.body.setAttribute('data-header-theme', 'light')
        } else {
          document.body.setAttribute('data-header-theme', 'dark')
        }
        let items = document.getElementsByClassName('menu-item');
        for (const item of items) {
          if (item.classList.contains('menu-item-deactivated')) {
            item.classList.remove('menu-item-deactivated');
            item.classList.add('menu-item-has-children');
          }
        }
        let shutter = document.getElementById('transition-shutter');
        shutter.classList.remove('activate');
        setTimeout(function () {
          animateEnter();
          sellingPoints();
          homeBanner();
          amenities();
          accordion();
          pricingTabs();
          swiperTestimonials();
          swiperTrustedBy();
          swiperRelatedArticles()
          conversationsHeader();
          billingTabs();
          openModals();
          initProcessAutopilot();
          initProcessHubspot();
          chatNowLink();
          calculator();
          slidesPin();
        }, 100);
        setTimeout(function () {
          data.next.container.classList.add('ready');
        }, 400);
      }
    }]

  });

  barba.hooks.once((data) => {

    let bafg_slider = document.body.querySelectorAll('.bafg-slider-info-wraper')
    if (bafg_slider) setTimeout(() => ScrollTrigger.refresh(), 5000);
    // setTimeout(() => console.log('bafg-slider-5000'), 5000);

  })

  barba.hooks.after((data) => {

    let bafg_slider = document.body.querySelectorAll('.bafg-slider-info-wraper')
    if (bafg_slider) setTimeout(() => ScrollTrigger.refresh(), 5000);
    // console.log('bafg-slider')

    let js = document.querySelectorAll('main script');
    if (js != null) {
      js.forEach((item) => {
        eval(item.innerHTML);
      });
    }

  });


}

export default initBarba
