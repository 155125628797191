import gsap from 'gsap';

export const homeBanner = () => {

    let svg = document.querySelector('[data-banner-svg]')

    if (!svg) return

    let background = document.querySelector('[data-banner-background]')

    let first_panel = svg.querySelector('[data-panel="1"]'),
        second_panel = svg.querySelector('[data-panel="2"]'),
        third_panel = svg.querySelector('[data-panel="3"]')

    let avatar = svg.querySelector('[data-element="avatar"]'),
        message = svg.querySelectorAll('[data-element="message"]'),
        words = svg.querySelectorAll('[data-element="word"]'),
        meta = svg.querySelector('[data-element="meta"]'),
        phone = svg.querySelector('[data-element="phone"]')

    gsap.set(background, { transformOrigin: 'right center' })
    gsap.set([avatar, phone], { transformOrigin: 'center center' })

    let tl = gsap.timeline({ delay: 1.5, })

    tl.addLabel('start');

    tl.fromTo(background, {
        scaleX: 0,
    }, {
        scaleX: 1,
        duration: 2,
        ease: 'power3.out',
    }, 'start')

    tl.fromTo(first_panel, {
        x: 350,
        y: 350,
        opacity: 0,
    }, {
        x: 0,
        y: 0,
        opacity: 1,
        duration: 1.4,
        ease: 'power4.out',
    }, 'start+=0.6')

    tl.fromTo(second_panel, {
        x: 350,
        y: 350,
        opacity: 0,
    }, {
        x: 0,
        y: 0,
        opacity: 1,
        duration: 1.4,
        ease: 'power4.out',
    }, 'start+=0.8')

    tl.addLabel('thirdpanel', 'start+=1.2');

    tl.fromTo(avatar, {
        scale: 0,
    }, {
        scale: 1,
        duration: 0.8,
        ease: 'back.out(2)',
    }, 'thirdpanel')

    tl.fromTo(third_panel, {
        x: '-200',
    }, {
        x: 0,
        duration: 0.8,
        ease: 'power2.inOut',
    }, 'thirdpanel+=0.6')

    tl.fromTo(message, {
        x: '101%',
    }, {
        x: 0,
        duration: 0.8,
        ease: 'power2.inOut',
    }, 'thirdpanel+=0.6')

    tl.fromTo(words, {
        opacity: 0,
    }, {
        opacity: 1,
        duration: 0.15,
        stagger: 0.02,
    }, 'thirdpanel+=1.2')

    tl.fromTo(meta, {
        y: '-65%',
    }, {
        y: 0,
        duration: 1.2,
        ease: 'power3.out',
    }, 'thirdpanel+=1.8')

    tl.fromTo(phone, {
        rotate: '-8deg',
    }, {
        rotate: '8deg',
        yoyo: true,
        repeat: 5,
        duration: 0.3,
        ease: 'power1.inOut',
    }, 'thirdpanel+=2')

}

export default homeBanner