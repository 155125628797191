
const chatNowLink = () => {

  let button = document.getElementById('chat-now-link')

  if (!button) return false

  button.addEventListener('click', function(e) {

    let icons = document.querySelectorAll('.gnattaChat button')

    for (const icon of icons) {
      if ( !icon.classList.contains('hidden') ) {
        icon.click()
      }
    }

    e.preventDefault()

  })

}

export default chatNowLink
