import Swiper, { Navigation, Pagination, A11y } from 'swiper';
Swiper.use([Navigation ,Pagination, A11y]);

const swiperRelatedArticles = () => {

    const swiper = document.querySelectorAll('[data-related-articles-swiper]');

    if (!swiper.length) return false;

    let related = new Swiper('[data-related-articles-swiper]', {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        grabCursor: true,
        pagination: {
            el: '[data-related-articles-swiper-pagination]',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button.next',
            prevEl: '.swiper-button.prev',
        },
        breakpoints: {
            600: {
                slidesPerView: 2,
                spaceBetween: 30
            },
            1000: {
                slidesPerView: 3,
                spaceBetween: 40
            }
        }
    })
}

export default swiperRelatedArticles;