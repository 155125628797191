import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const amenities = () => {

  let amenities = document.getElementsByClassName('amenity');

  if (!amenities) return false;

  for (const amenity of amenities) {

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: amenity,
        start: 'top 85%',
        end: 'bottom 85%',
        scrub: true,
        // markers: true,
      }
    });

    tl.addLabel("start");

    if ( amenity.getElementsByClassName('background').length > 0 ) {
      tl.from(amenity.getElementsByClassName('background'), {
        ease: 'power1.inOut',
        scaleY: '0.75',
        duration: 0.5,
        transformOrigin: '50% 100%',
        stagger: {
          each: 0.1,
          from: 'center',
        }
      }, 'start');
    }

    if ( amenity.getElementsByClassName('bubble').length > 0 ) {
      tl.from(amenity.getElementsByClassName('bubble'), {
        ease: 'power1.out',
        translateY: 150,
        duration: 0.3,
        opacity: 0,
        stagger: 0.1,
      }, 'start+=0.1');
    }

    if ( amenity.getElementsByClassName('window').length > 0 ) {
      tl.from(amenity.getElementsByClassName('window'), {
        ease: 'power1.out',
        translateY: 150,
        duration: 0.3,
        opacity: 0,
        stagger: 0.1,
      }, 'start+=0.1');
    }

    if ( amenity.getElementsByClassName('skk1').length > 0 ) {
      tl.from(amenity.getElementsByClassName('skk1'), {
        ease: 'power1.inOut',
        scaleY: 0,
        duration: 0.5,
        stagger: 0.05,
        transformOrigin: '50% 100%',
      }, 'start+=0.02');
    }

    if ( amenity.getElementsByClassName('skk2').length > 0 ) {
      tl.from(amenity.getElementsByClassName('skk2'), {
        ease: 'power1.inOut',
        scaleY: 0,
        duration: 0.5,
        stagger: 0.05,
        transformOrigin: '50% 100%',
      }, 'start+=0.12');
    }

    if ( amenity.getElementsByClassName('skk3').length > 0 ) {
      tl.from(amenity.getElementsByClassName('skk3'), {
        ease: 'power1.inOut',
        scaleY: 0,
        duration: 0.5,
        stagger: 0.05,
        transformOrigin: '50% 100%',
      }, 'start+=0.22');
    }

  }
}

export default amenities;
