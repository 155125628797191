
const closeModals = () => {

  let links = document.getElementsByClassName('close-modal');

  if (!links) return false;

  for (const link of links) {

    link.addEventListener('click', function(e) {
      document.body.classList.remove('reveal-download-modal');
      document.body.classList.remove('reveal-newsletter-modal');
      document.body.classList.remove('reveal-contact-modal');
    });

  }
}

export const openModals = () => {

  let links = document.getElementsByTagName('a');

  if (!links) return false;

  for (const link of links) {
    if ( link.getAttribute('href').indexOf('download-modal') !== -1 ) {
      downloadModals(link);
    } else if ( link.getAttribute('href').indexOf('newsletter-modal') !== -1 ) {
      newsletterModals(link);
    } else if ( link.getAttribute('href').indexOf('contact-modal') !== -1 ) {
      contactModals(link);
    }
  }
}

const downloadModals = (link) => {
  link.addEventListener('click', function(e) {

    document.body.classList.add('reveal-download-modal');

    let split_href = link.getAttribute('href').split('-'),
        download_id = split_href.pop(),
        download_form = document.getElementsByClassName('download-modal-form')[0],
        confirmation_url = download_form.getAttribute('data-confirmation') + '?id=' + download_id,
        download_form_id = 'Download Pop-up Modal Form – Download File ID: ' + download_id,
        download_file_field = download_form.querySelectorAll('.download-id')[0];

    document.body.setAttribute('data-confirmation', confirmation_url);
    document.body.setAttribute('data-download-id', download_id);

    download_form.setAttribute('data-confirmation', confirmation_url);
    download_form.setAttribute('data-download-id', download_id);
    download_form.setAttribute('id', download_form_id);
    download_file_field.value = download_id;

    e.preventDefault();
  });
}

const newsletterModals = (link) => {
  link.addEventListener('click', function(e) {
    document.body.classList.add('reveal-newsletter-modal');
    e.preventDefault();
  });
}

const contactModals = (link) => {
  link.addEventListener('click', function(e) {
    document.body.classList.add('reveal-contact-modal');
    e.preventDefault();
  });
}

export default closeModals;