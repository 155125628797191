import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const sellingPoints = () => {

  let selling_points = document.getElementsByClassName('selling-point-illustration'),
      illustrationRotation = document.getElementsByClassName('illustration-rotation');

  if (!selling_points || !illustrationRotation) return false;

  if ( illustrationRotation.length > 0 ) {
    gsap.to(illustrationRotation, {
      rotation: -360,
      ease: 'none',
      duration: 50,
      repeat: 1,
      transformOrigin: '50% 50%',
    });
  }

  for (const selling_point of selling_points) {

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: selling_point,
        start: 'top 90%',
        end: 'bottom 10%',
        duration: 1,
        scrub: true,
        // markers: true,
      }
    });

    let direction = selling_point.classList.contains('selling-point-illustration-2') ? 1 : -1;

    tl.addLabel("start");

    tl.from(selling_point.getElementsByClassName('grey-circle')[0], {
      ease: 'power1.inOut',
      scale: '0.5',
      duration: 0.5,
      transformOrigin: '50% 50%',
    }, 'start');

    tl.from(selling_point.getElementsByClassName('fleck'), {
      x: 80 * direction,
      duration: 1,
    }, 'start');

    tl.from(selling_point.getElementsByClassName('horizonal-translation')[0], {
      x: 100 * direction,
      ease: 'power1.inOut',
      scale: '0.8',
      duration: 0.5,
    }, 'start');

    tl.from(selling_point.getElementsByClassName('orb'), {
      opacity: 0,
      x: 50 * direction,
      duration: 0.2,
      stagger: 0.05,
    }, 'start');

    if ( selling_point.classList.contains('selling-point-illustration-1') ) {

      tl.from(selling_point.getElementsByClassName('spindals')[0], {
        rotation: 140,
        ease: 'power2.inOut',
        duration: 1,
        transformOrigin: '50% 50%',
      }, 'start');

    } else if ( selling_point.classList.contains('selling-point-illustration-2') ) {

      tl.to(selling_point.getElementsByClassName('dial-level')[0], {
        rotation: 180,
        ease: 'power2.inOut',
        duration: 0.7,
        transformOrigin: '50% 0',
      }, 'start');

      tl.to(selling_point.getElementsByClassName('dial')[0], {
        rotation: 180,
        ease: 'power2.inOut',
        duration: 0.7,
        transformOrigin: '50% 50%',
      }, 'start');

      tl.from(selling_point.getElementsByClassName('face-sad')[0], {
        duration: 0.07,
        scale: 1.35,
        transformOrigin: '50% 50%',
      }, 'start+=0.27');

      tl.to(selling_point.getElementsByClassName('face-medium')[0], {
        duration: 0.07,
        scale: 1.35,
        transformOrigin: '50% 50%',
      }, 'start+=0.27');

      tl.to(selling_point.getElementsByClassName('face-medium')[0], {
        duration: 0.07,
        scale: 1,
        transformOrigin: '50% 50%',
      }, 'start+=0.39');

      tl.to(selling_point.getElementsByClassName('face-happy')[0], {
        duration: 0.07,
        scale: 1.35,
        transformOrigin: '50% 50%',
      }, 'start+=0.39');

    } else if ( selling_point.classList.contains('selling-point-illustration-3') ) {

      tl.from(selling_point.getElementsByClassName('bar-rise'), {
        scaleY: 0,
        duration: 0.25,
        stagger: 0.05,
        transformOrigin: '50% 100%',
      }, 'start+=0.3');

    }
  }

}

export default sellingPoints;
