import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const defaultConversation = () => {

}

export const conversationsHeader = () => {
/*
  let wrappers = document.getElementsByClassName('conversations-wrapper');

  if (!wrappers) return false;

  for (const wrapper of wrappers) {

    let header = wrapper.getElementsByClassName('conversations-header'),
        content = wrapper.getElementsByClassName('conversations'),
        calc_end = '+=' + content[0].offsetHeight + 'px';

    if ( window.innerWidth >= 782 ) {

      gsap.to(header, {
        scrollTrigger: {
          trigger: header,
          start: 'bottom 300',
          end: calc_end,
          pin: true,
          pinSpacing: false,
          anticipaePin: true,
          // markers: true,
        }
      });

    }
  }
*/
}

export default defaultConversation;
