import axios from 'axios';
import Bouncer from 'formbouncerjs';

var bouncer = new Bouncer('[data-validate]', {
  disableSubmit: true,
});

const processAutopilot = (e) => {
  e.preventDefault();
  const form = e.target;
  const formData = new FormData(form);

  for (var value of formData.values()) {
    // console.log(value);
  }

  document.addEventListener('bouncerFormInvalid', function(event) {
    console.log(event.detail.errors);
  }, false);

  document.addEventListener('bouncerFormValid', function(e) {

    form.classList.add('loading');

    var ap_first_name = formData.get('first_name') ? formData.get('first_name') : '',
        ap_last_name = formData.get('last_name') ? formData.get('last_name') : '',
        ap_email_address = formData.get('email_address') ? formData.get('email_address') : '',
        ap_company = formData.get('company') ? formData.get('company') : '',
        ap_source = formData.get('source') ? formData.get('source') : 'Gnatta Website',
        ap_page = formData.get('page') ? formData.get('page') : 'Gnatta Website',
        ap_download_id = formData.get('download_id') ? formData.get('download_id') : '–',
        // ap_trigger_id = formData.get('trigger_id') ? formData.get('trigger_id') : '0004',
        // ap_trigger_journey_url = 'https://api2.autopilothq.com/v1/trigger/' + ap_trigger_id + '/contact/',
        ap_list = formData.get('list') ? formData.get('list') : '';


    /*
     * Connect to Oribi API
     */

    console.log('Connect to Oribi API')

    ORIBI.api('setUserEmail', ap_email_address)

    axios({
      method: 'POST',
      url: 'https://api2.autopilothq.com/v1/contact',
      headers: {
        'autopilotapikey': '992e671d964846b2ab0610044ad31f20',
        'Content-Type': 'application/json',
      },
      data: {
        'contact': {
          'Email': ap_email_address,
          'FirstName': ap_first_name,
          'LastName': ap_last_name,
          'Company': ap_company,
          'LeadSource': ap_source,
          'custom': {
            'string--Page--Submitted--On': ap_page,
            'integer--Download--ID': parseInt(ap_download_id),
          },
          '_autopilot_list': ap_list,
        }
      }
    })
    .then(function(response) {
      console.log('%Autopilot Response:', 'color: #00FF00;');
      console.log(response);
      if ( document.body.hasAttribute('data-confirmation') ) {
        setTimeout(function() {
          window.location.href = document.body.getAttribute('data-confirmation');
        }, 1000);
      } else {
        setTimeout(function() {
          window.location.href = form.getAttribute('data-confirmation');
        }, 1000);
      }
    },
    (error) => {
      console.log(error);
    });

  }, false);

};

const initProcessAutopilot = () => {

  const forms = document.querySelectorAll('[data-process-autopilot]');

  for (let i = 0; i < forms.length; i++) {
    forms[i].addEventListener('submit', processAutopilot, false);
  }

};

export default initProcessAutopilot;