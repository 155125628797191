
const pricingTabs = () => {

  let tabs = document.getElementsByClassName('reveal-pricing-lower'),
      panels = document.getElementsByClassName('pricing-lower');

  if (!tabs || !panels) return false;

  for (const tab of tabs) {

    tab.addEventListener('click', function(e) {

      tab.nextElementSibling.style.display = 'block';
      tab.style.display = 'none';

      e.preventDefault();

    });

  }
}

export default pricingTabs;
