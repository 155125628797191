
const headerBackground = () => {

  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if ( currentScroll <= 5 ) {
      document.body.classList.remove('show-header-background');
    } else {
      document.body.classList.add('show-header-background');
    }
  });

}

export default headerBackground;
