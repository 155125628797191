import axios from 'axios';
import Bouncer from 'formbouncerjs';

var bouncer = new Bouncer('[data-validate]', {
  disableSubmit: true,
});

const processHubspot = (e) => {
  e.preventDefault();
  const form = e.target;

  const formData = new FormData(form);

  var date = new Date();

  formData.append('action', 'process_hubspot');
  formData.append('nonce', WP.nonce);
  formData.append('createdate', date.toISOString());

  for (var value of formData.values()) {
    // console.log(value);
  }

  document.addEventListener('bouncerFormInvalid', function(event) {
    console.log(event.detail.errors);
  }, false);

  document.addEventListener('bouncerFormValid', function(e) {

    axios({
      method: 'POST',
      url: WP.ajax,
      headers: {
        'Content-Type': 'application/json',
      },
      data: formData,
    })
    .then(function(response) {
      form.style.opacity = 0.3;
      console.log('%cHubspot Response:', 'color: #00FF00;');
      console.log(response);
      setTimeout(function() {
        window.location.href = form.getAttribute('data-confirmation');
      }, 1000);
   },
    (error) => {
      console.log(error);
    });

  }, false);

};

const initProcessHubspot = () => {

  const forms = document.querySelectorAll('[data-process-hubspot]');

  for (let i = 0; i < forms.length; i++) {
    forms[i].addEventListener('submit', processHubspot, false);
  }

};

export default initProcessHubspot;